exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ButtonGroup__segmented__3fPy6 .ButtonGroup__item__3DOZA.ButtonGroup__primary__2ZVow{margin-left:1px}", ""]);

// exports
exports.locals = {
	"segmented": "ButtonGroup__segmented__3fPy6",
	"item": "ButtonGroup__item__3DOZA",
	"primary": "ButtonGroup__primary__2ZVow"
};