import axiosInstance from "config/axios";

class ApiService {
  get redirectOrigin() {
    return localStorage.getItem("redirect_origin");
  }

  setRedirectOrigin(url) {
    localStorage.setItem("redirect_origin", url);
  }

  get redirectUrl() {
    return localStorage.getItem("redirect_url");
  }

  setRedirectUrl(url) {
    localStorage.setItem("redirect_url", url);
  }

  get clientId() {
    return localStorage.getItem("client_id");
  }

  setClientId(client_id) {
    localStorage.setItem("client_id", client_id);
  }

  preAuthRequest = (payload) => {
    const url = `${this.redirectOrigin}/api/v1/auth/token/preauthrequest`;
    return axiosInstance.post(url, payload);
  };

  getLoginPageConfig = (payload) => {
    const url = `${this.redirectOrigin}/api/v1/auth/loginInfo`;
    return axiosInstance.get(url, { params: payload });
  };

  resetPassword = (emailAddress) => {
    const url = `${this.redirectOrigin}/api/v1/users/resetpassword`;
    return axiosInstance.post(url, { emailAddress });
  };

  confirmResetPassword = (emailAddress, verificationCode, password) => {
    // ex: {emailAddress: '', password:'', verificationCode:''}
    const url = `${this.redirectOrigin}/api/v1/users/confirmresetpassword`;
    return axiosInstance.post(url, {
      emailAddress,
      verificationCode,
      password,
    });
  };
}

const apiService = new ApiService();
export default apiService;
