exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FlexBox__flex__Z0xZY{display:-ms-flexbox;display:flex}.FlexBox__inline__dXCp4{display:-ms-inline-flexbox;display:inline-flex}.FlexBox__column__30WF0{-ms-flex-direction:column;flex-direction:column}.FlexBox__rowReverse__1pKT3{-ms-flex-direction:row-reverse;flex-direction:row-reverse}.FlexBox__columnReverse__1dUB1{-ms-flex-direction:column-reverse;flex-direction:column-reverse}.FlexBox__row__jqmKc{-ms-flex-direction:row;flex-direction:row}.FlexBox__contentEnd__3zIwF{-ms-flex-pack:end;justify-content:flex-end}.FlexBox__contentCenter__1wktM{-ms-flex-pack:center;justify-content:center}.FlexBox__contentAround__1umsm{-ms-flex-pack:distribute;justify-content:space-around}.FlexBox__contentBetween__3nZeD{-ms-flex-pack:justify;justify-content:space-between}.FlexBox__contentStart__30yrG{-ms-flex-pack:start;justify-content:flex-start}.FlexBox__alignStart__1sc-U{-ms-flex-align:start;align-items:flex-start;-ms-flex-line-pack:start;align-content:flex-start}.FlexBox__alignEnd__1m9n4{-ms-flex-align:end;align-items:flex-end;-ms-flex-line-pack:end;align-content:flex-end}.FlexBox__alignCenter__1dSSb{-ms-flex-align:center;align-items:center;-ms-flex-line-pack:center;align-content:center}.FlexBox__alignStretch__zt9Eb{-ms-flex-align:stretch;align-items:stretch;-ms-flex-line-pack:stretch;align-content:stretch}.FlexBox__noWrap__JlC2S{-ms-flex-wrap:nowrap;flex-wrap:nowrap}.FlexBox__wrap__3dACD{-ms-flex-wrap:wrap;flex-wrap:wrap}.FlexBox__wrapReverse__mtndi{-ms-flex-wrap:wrap-reverse;flex-wrap:wrap-reverse}", ""]);

// exports
exports.locals = {
	"flex": "FlexBox__flex__Z0xZY",
	"inline": "FlexBox__inline__dXCp4",
	"column": "FlexBox__column__30WF0",
	"rowReverse": "FlexBox__rowReverse__1pKT3",
	"columnReverse": "FlexBox__columnReverse__1dUB1",
	"row": "FlexBox__row__jqmKc",
	"contentEnd": "FlexBox__contentEnd__3zIwF",
	"contentCenter": "FlexBox__contentCenter__1wktM",
	"contentAround": "FlexBox__contentAround__1umsm",
	"contentBetween": "FlexBox__contentBetween__3nZeD",
	"contentStart": "FlexBox__contentStart__30yrG",
	"alignStart": "FlexBox__alignStart__1sc-U",
	"alignEnd": "FlexBox__alignEnd__1m9n4",
	"alignCenter": "FlexBox__alignCenter__1dSSb",
	"alignStretch": "FlexBox__alignStretch__zt9Eb",
	"noWrap": "FlexBox__noWrap__JlC2S",
	"wrap": "FlexBox__wrap__3dACD",
	"wrapReverse": "FlexBox__wrapReverse__mtndi"
};