import emgageLogo from "static/images/emgage-logo.png";
import emgageSplashImage from "static/images/emgage-splash.png";
import emgageIcon from "static/images/emgage-icon.png";
import eSpinner from "static/images/eSpinner.svg";
import eSpinnerOrbit from "static/images/eSpinner-orbit.svg";

const images = {
  emgageLogo,
  emgageSplashImage,
  emgageIcon,
  eSpinner,
  eSpinnerOrbit,
};

export default images;
