// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignIn_SignIn__RoSMl .SignIn_inputTypeRow__S4bLA {
  margin-bottom: 1.6rem; }

.SignIn_SignIn__RoSMl .SignIn_signInButton__h-UCd {
  margin-bottom: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/SignIn/SignIn.module.scss"],"names":[],"mappings":"AAAA;EAKQ,qBAAqB,EAAA;;AAL7B;EAQQ,mBAAmB,EAAA","sourcesContent":[".SignIn {\n    // .commonMargin {\n    //     margin: 10px 5px;\n    // }\n    .inputTypeRow{\n        margin-bottom: 1.6rem;\n    }\n    .signInButton{\n        margin-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SignIn": `SignIn_SignIn__RoSMl`,
	"inputTypeRow": `SignIn_inputTypeRow__S4bLA`,
	"signInButton": `SignIn_signInButton__h-UCd`
};
export default ___CSS_LOADER_EXPORT___;
