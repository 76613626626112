// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmgageSpinner_EmgageSpinner__f4Vlh {
  position: absolute;
  left: 53%;
  top: 53%;
  z-index: 10000;
  transform: translate(-53%, -53%); }
  .EmgageSpinner_EmgageSpinner__f4Vlh .EmgageSpinner_spinnerText__aLSfN {
    position: absolute;
    left: 55%;
    transform: translate(-50%, 45px);
    width: 102px;
    text-align: center; }

.EmgageSpinner_eSpinner__dcJ3k {
  position: absolute;
  left: -10px;
  top: -10px;
  animation: EmgageSpinner_spin__WtjE4 1.3s linear infinite; }

@keyframes EmgageSpinner_spin__WtjE4 {
  to {
    transform: rotate(360deg); } }
`, "",{"version":3,"sources":["webpack://./src/components/EmgageSpinner/EmgageSpinner.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,cAAc;EACd,gCAAgC,EAAA;EALlC;IAQI,kBAAkB;IAClB,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,kBAAkB,EAAA;;AAItB;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,yDAAoC,EAAA;;AAGtC;EACE;IACE,yBAAyB,EAAA,EAAA","sourcesContent":[".EmgageSpinner {\n  position: absolute;\n  left: 53%;\n  top: 53%;\n  z-index: 10000;\n  transform: translate(-53%, -53%);\n\n  .spinnerText {\n    position: absolute;\n    left: 55%;\n    transform: translate(-50%, 45px);\n    width: 102px;\n    text-align: center;\n  }\n}\n\n.eSpinner {\n  position: absolute;\n  left: -10px;\n  top: -10px;\n  animation: spin 1.3s linear infinite;\n}\n\n@keyframes spin {\n  to {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EmgageSpinner": `EmgageSpinner_EmgageSpinner__f4Vlh`,
	"spinnerText": `EmgageSpinner_spinnerText__aLSfN`,
	"eSpinner": `EmgageSpinner_eSpinner__dcJ3k`,
	"spin": `EmgageSpinner_spin__WtjE4`
};
export default ___CSS_LOADER_EXPORT___;
