exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MyDropzone__MyDropzone__1STUL .MyDropzone__dragAndDropWrap__33_W-{border:1px dashed #1976d2;text-align:center;padding:1.5rem;border-radius:.4rem;background:rgba(25,118,210,.1)}.MyDropzone__MyDropzone__1STUL .MyDropzone__uploadedFileList__I2ofn .MyDropzone__fileRow__agHJc{padding:1rem 2rem;border-bottom:1px solid #e5e5e5}.MyDropzone__MyDropzone__1STUL .MyDropzone__uploadedFileList__I2ofn .MyDropzone__fileRow__agHJc:last-child{border:0}", ""]);

// exports
exports.locals = {
	"MyDropzone": "MyDropzone__MyDropzone__1STUL",
	"dragAndDropWrap": "MyDropzone__dragAndDropWrap__33_W-",
	"uploadedFileList": "MyDropzone__uploadedFileList__I2ofn",
	"fileRow": "MyDropzone__fileRow__agHJc"
};