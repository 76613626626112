import React from "react";
import * as Yup from "yup";
import { Button, FlexBox, Icon, TextField, Heading, BodyText } from "engage-ui";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import useEnterEvent from "hooks/useEnterEvent";

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]+$/, "Verification code must be only digits")
    .required("Verification code is required")
    .test(
      "len",
      "Verification code must be exactly 6 characters",
      (val) => (val || "").length === 6
    ),
});

const TwoFA = ({
  rememberMe,
  user,
  theme,
  setApiError,
  onCancel,
  apiErrorComponent,
}) => {
  const history = useHistory();
  const formicRef = React.useRef();
  const listenEnterEvent = () => {
    if (!formicRef.current.isSubmitting) {
      formicRef.current.handleSubmit();
    }
  };
  useEnterEvent(listenEnterEvent);

  const submitCode = (values, { setSubmitting }) => {
    Auth.confirmSignIn(
      user, // Return object from Auth.signIn()
      values.code, // Confirmation code
      user.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    )
      .then(async (c) => {
        if (rememberMe) {
          await Auth.rememberDevice();
        } else {
          await Auth.forgetDevice();
        }
        setSubmitting(false);
        history.push("/login-callback");
      })
      .catch((error) => {
        setSubmitting(false);
        setApiError(error.message);
        console.log(error.code, error.message);
      });
  };

  return (
    <Formik
      initialValues={{ code: "" }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        submitCode(values, { setSubmitting });
      }}
      innerRef={formicRef}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <FlexBox direction="Column" componentClass={theme.TwoFA}>
          <div className={theme.inputTypeRow}>
            <Heading componentClass={theme.headingTitle} headingSize="h2">
              Verify its you
            </Heading>
            <BodyText componentColor="text" componentSize="default">
              We sent a verification code to your registered 2FA (Two Factor
              Authentication) method type.
            </BodyText>
          </div>
          {apiErrorComponent}
          <div className={theme.inputTypeRow}>
            <TextField
              type="text"
              name="code"
              onChange={(_v, e) => handleChange(e)}
              onBlur={handleBlur}
              value={values.code}
              errors={touched.code && errors.code && [errors.code]}
              placeholder="Verification code"
              placeholderAlign="left"
              prefix={<Icon source="chat" componentColor="inkLighter" />}
            />
          </div>

          <Button
            componentClass={theme.confirmInButton}
            primary
            disabled={isSubmitting}
            onClick={handleSubmit}
            title="Confirm"
            componentSize="large"
          >
            Confirm and Sign In
          </Button>
          <Button
            componentClass={theme.confirmInButton}
            disabled={isSubmitting}
            onClick={onCancel}
            title="Cancel"
            componentSize="large"
          >
            Cancel
          </Button>
        </FlexBox>
      )}
    </Formik>
  );
};

export default TwoFA;
