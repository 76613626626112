exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".List__list__10zvG{list-style:none;margin-top:0;padding-left:0}.List__list__10zvG+.List__list__10zvG{margin-top:1.6rem}.List__list__10zvG ul{padding-left:2rem}.List__typeBullet__JPJPN>.List__item__35bCm:before{content:\"\\2022\";font-size:1.8rem}.List__typeNumber__2sBHc{counter-reset:a}.List__typeNumber__2sBHc>.List__item__35bCm:before{font-weight:700;content:counter(a,decimal) \".\";counter-increment:a;font-size:1.2rem;vertical-align:top}.List__list-divider__gfYVh{margin:0}.List__list-divider__gfYVh+div{padding:1.6rem 2.4rem;margin:0 auto}.List__list-divider__gfYVh>li:nth-child(n+2){margin:0;padding-top:1rem;border-top:1px solid #e5e5e5}.List__list-divider__gfYVh>li{padding:1.5rem;margin:0}.List__list-divider__gfYVh>li span:first-child{font-size:1.3rem;color:#616161;min-width:4.5rem;display:inline-block}.List__list-divider__gfYVh>li span:first-child,.List__list-divider__gfYVh>li span:nth-child(2){font-weight:400;font-stretch:normal;font-style:normal;line-height:1.23;letter-spacing:normal}.List__list-divider__gfYVh>li span:nth-child(2){font-size:1.4rem;margin-left:2.5rem}.List__list-divider__gfYVh>li:last-child{border-bottom:1px solid #dbdbdb}.List__list-striped__1udPj>li{padding:1rem}.List__list-striped__1udPj>li:nth-of-type(odd){border-top:1px solid #e5e5e5;border-bottom:1px solid #e5e5e5;background:#f8f8f8}.List__list-striped__1udPj>li:nth-child(n+2){margin-top:0}.List__item__35bCm{position:relative;padding-left:1.6rem}.List__item__35bCm:last-child{margin-bottom:0}.List__item__35bCm:before{position:absolute;top:0;left:0;display:block}.List__item__35bCm .List__naked__1E6f0{list-style-type:none}.List__item__35bCm .List__list__10zvG:first-child{margin-top:.8rem}", ""]);

// exports
exports.locals = {
	"list": "List__list__10zvG",
	"typeBullet": "List__typeBullet__JPJPN",
	"item": "List__item__35bCm",
	"typeNumber": "List__typeNumber__2sBHc",
	"list-divider": "List__list-divider__gfYVh",
	"list-striped": "List__list-striped__1udPj",
	"naked": "List__naked__1E6f0"
};