import React from "react";
import { Auth } from "aws-amplify";
import { Button, FlexBox } from "engage-ui";
import images from "data/images";

const SocialSignIn = ({ theme, providers = [] }) => {
  if (providers.length === 0) {
    return null;
  }
  return (
    <FlexBox
      componentClass={theme.SocialSignIn}
      wrap="Wrap"
      justify="SpaceAround"
      direction="Column"
    >
      {providers.map(({ identityProvider, loginText, logoUrl }) => {
        return (
          <Button
            key={identityProvider}
            componentClass={theme.button}
            onClick={() => {
              Auth.federatedSignIn({ customProvider: identityProvider });
            }}
          >
            <img alt="logo image" src={logoUrl || images.emgageIcon} /> {loginText}
          </Button>
        );
      })}
    </FlexBox>
  );
};

export default SocialSignIn;
