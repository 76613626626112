exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Labelled__labelWrapper__2Qdun{margin-bottom:0;margin-left:1.2rem;position:absolute;z-index:19;transform:translate3D(0,1.2rem,0);transition:all .2s ease-out;-webkit-backface-visibility:hidden;backface-visibility:hidden}.Labelled__labelWrapper__2Qdun.Labelled__focused__4lxg5,.Labelled__labelWrapper__2Qdun:not(.Labelled__empty__3E6Fv){transform:translate3D(0,.1rem,0)}.Labelled__helpText__3jYsK{font-size:1.3rem;font-weight:400;line-height:1.6rem;text-transform:none;letter-spacing:normal;color:#616161;margin-top:.3rem}@media (min-width:40em){.Labelled__helpText__3jYsK{font-size:1.3rem}}.Labelled__helpText__3jYsK.Labelled__disabled__2dzvb{color:#9e9e9e}", ""]);

// exports
exports.locals = {
	"labelWrapper": "Labelled__labelWrapper__2Qdun",
	"focused": "Labelled__focused__4lxg5",
	"empty": "Labelled__empty__3E6Fv",
	"helpText": "Labelled__helpText__3jYsK",
	"disabled": "Labelled__disabled__2dzvb"
};