// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPassword_ForgotPassword__x37kn .ForgotPassword_inputTypeRow__i7-JX {
  margin-bottom: 1.6rem; }

.ForgotPassword_ForgotPassword__x37kn .ForgotPassword_signInButton__KgnPC {
  margin-bottom: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/ForgotPassword/ForgotPassword.module.scss"],"names":[],"mappings":"AAAA;EAKQ,qBAAqB,EAAA;;AAL7B;EAQQ,mBAAmB,EAAA","sourcesContent":[".ForgotPassword {\n    // .commonMargin {\n    //     margin: 10px 5px;\n    // }\n    .inputTypeRow{\n        margin-bottom: 1.6rem;\n    }\n    .signInButton{\n        margin-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ForgotPassword": `ForgotPassword_ForgotPassword__x37kn`,
	"inputTypeRow": `ForgotPassword_inputTypeRow__i7-JX`,
	"signInButton": `ForgotPassword_signInButton__KgnPC`
};
export default ___CSS_LOADER_EXPORT___;
