// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_Footer__MEpnh {
  text-align: center;
  margin: 10px; }
  .Footer_Footer__MEpnh a {
    color: #757575; }
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,YAAY,EAAA;EAFhB;IAIM,cAAc,EAAA","sourcesContent":[".Footer {\n    text-align: center;\n    margin: 10px;\n    a {\n      color: #757575;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": `Footer_Footer__MEpnh`
};
export default ___CSS_LOADER_EXPORT___;
