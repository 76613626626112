exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Segment__SegmentWrap__11HhF{border:1px solid #bdbdbd;padding:.4rem;border-radius:.8rem;background:#f2f2f2}.Segment__SegmentWrap__11HhF .Segment__SegmentButton__6pJSM{border:1px solid transparent;background:transparent;width:100%;height:auto;padding:0;margin-right:.4rem}.Segment__SegmentWrap__11HhF .Segment__SegmentButton__6pJSM:last-child{margin-right:0}.Segment__SegmentWrap__11HhF .Segment__SegmentButton__6pJSM.Segment__active__3_9Kj{background:#fff}.Segment__SegmentWrap__11HhF .Segment__SegmentButton__6pJSM.Segment__active__3_9Kj,.Segment__SegmentWrap__11HhF .Segment__SegmentButton__6pJSM:focus,.Segment__SegmentWrap__11HhF .Segment__SegmentButton__6pJSM:hover{fill:#212121;color:#212121;border-color:#bdbdbd}.Segment__SegmentWrap__11HhF .Segment__SegmentButton__6pJSM .Segment__segmentLabel__3OMHt{color:#212121}.Segment__SegmentWrap__11HhF .Segment__SegmentButton__6pJSM .Segment__segmentSubText__2Gsnb{color:#212121;opacity:.8}.Segment__SegmentWrap__11HhF .Segment__SegmentButton__6pJSM .Segment__labelIconWrap__WuXnk .Segment__labelIcon__3a6ZN{margin-right:.9rem}", ""]);

// exports
exports.locals = {
	"SegmentWrap": "Segment__SegmentWrap__11HhF",
	"SegmentButton": "Segment__SegmentButton__6pJSM",
	"active": "Segment__active__3_9Kj",
	"segmentLabel": "Segment__segmentLabel__3OMHt",
	"segmentSubText": "Segment__segmentSubText__2Gsnb",
	"labelIconWrap": "Segment__labelIconWrap__WuXnk",
	"labelIcon": "Segment__labelIcon__3a6ZN"
};