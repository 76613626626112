exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Labelled__hidden__GzBjP>.Labelled__labelWrapper__2psUx{position:absolute!important;top:0;clip:rect(1px,1px,1px,1px)!important;overflow:hidden!important;height:1px!important;width:1px!important;padding:0!important;border:0!important}.Labelled__labelWrapper__2psUx{margin-bottom:.4rem;white-space:nowrap;text-overflow:ellipsis;max-width:30rem}.Labelled__helpText__DDbN2{font-size:1.4rem;font-weight:400;line-height:2rem;text-transform:none;letter-spacing:normal;color:#637381;margin-top:.4rem}@media (min-width:40em){.Labelled__helpText__DDbN2{font-size:1.4rem}}body .Labelled__readOnly__1EQ9v{margin-left:0}", ""]);

// exports
exports.locals = {
	"hidden": "Labelled__hidden__GzBjP",
	"labelWrapper": "Labelled__labelWrapper__2psUx",
	"helpText": "Labelled__helpText__DDbN2",
	"readOnly": "Labelled__readOnly__1EQ9v"
};