exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ChoiceList__choiceList__kxyxz{margin:0;padding:0;border:none}.ChoiceList__titleHidden__3GzFV>.ChoiceList__title__3VndN{position:absolute!important;top:0;clip:rect(1px,1px,1px,1px)!important;overflow:hidden!important;height:1px!important;width:1px!important;padding:0!important;border:0!important;color:#000;background-color:#fff}.ChoiceList__choices__11GGF{list-style:none;margin:0;padding:0}.ChoiceList__choicesHorizontal__2_cNl{list-style:none;display:-ms-flexbox;display:flex;margin:0;padding-left:0}.ChoiceList__choicesHorizontal__2_cNl li{margin-right:3rem}.ChoiceList__title__3VndN{font-size:1.4rem;font-weight:400;line-height:2rem;text-transform:none;letter-spacing:normal;display:block;margin:0 0 .4rem;padding:0;font-weight:600}@media (min-width:40em){.ChoiceList__title__3VndN{font-size:1.4rem}}.ChoiceList__helpText__3JhSX{font-size:1.4rem;font-weight:400;line-height:2rem;text-transform:none;letter-spacing:normal;color:#637381;margin-bottom:.4rem}@media (min-width:40em){.ChoiceList__helpText__3JhSX{font-size:1.4rem}}", ""]);

// exports
exports.locals = {
	"choiceList": "ChoiceList__choiceList__kxyxz",
	"titleHidden": "ChoiceList__titleHidden__3GzFV",
	"title": "ChoiceList__title__3VndN",
	"choices": "ChoiceList__choices__11GGF",
	"choicesHorizontal": "ChoiceList__choicesHorizontal__2_cNl",
	"helpText": "ChoiceList__helpText__3JhSX"
};