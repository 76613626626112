// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialSignIn_SocialSignIn__\\+gWbm {
  margin: 10px 0px; }
  .SocialSignIn_SocialSignIn__\\+gWbm .SocialSignIn_button__6ZETo {
    margin-bottom: 20px;
    min-width: 150px;
    width: 100%; }
    .SocialSignIn_SocialSignIn__\\+gWbm .SocialSignIn_button__6ZETo img {
      height: 2.4rem;
      width: auto;
      vertical-align: middle;
      margin-right: 12px;
      margin-top: -2px; }
  .SocialSignIn_SocialSignIn__\\+gWbm .SocialSignIn_noProviders__D3hqa {
    width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/SocialSignIn/SocialSignIn.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAgB,EAAA;EADpB;IAIQ,mBAAmB;IACnB,gBAAgB;IAChB,WAAW,EAAA;IANnB;MASY,cAAc;MACd,WAAW;MACX,sBAAsB;MACtB,kBAAkB;MAClB,gBAAgB,EAAA;EAb5B;IAkBQ,WAAW,EAAA","sourcesContent":[".SocialSignIn {\n    margin: 10px 0px;\n\n    .button {\n        margin-bottom: 20px;\n        min-width: 150px;\n        width: 100%;\n\n        img {\n            height: 2.4rem;\n            width: auto;\n            vertical-align: middle;\n            margin-right: 12px;\n            margin-top: -2px;\n        }\n    }\n\n    .noProviders {\n        width: 100%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SocialSignIn": `SocialSignIn_SocialSignIn__+gWbm`,
	"button": `SocialSignIn_button__6ZETo`,
	"noProviders": `SocialSignIn_noProviders__D3hqa`
};
export default ___CSS_LOADER_EXPORT___;
