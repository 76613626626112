import apiService from "services/api.service";

const getPostSignInRedirectUrl = async (curUser) => {
  const payload = {
    sub: curUser.attributes.sub,
    token: curUser.signInUserSession.idToken.jwtToken,
  };
  let response;
  try {
    response = await apiService.preAuthRequest(payload);
  } catch (er) {
    console.log(er);
    return null;
  }
  let urlParams = {
    returnUrl: apiService.redirectUrl,
    identifier: response.data,
    email: curUser.attributes.email,
    providerName: "",
    providerType: "",
  };
  if (curUser.attributes.identities) {
    try {
      const [identifierData] = JSON.parse(curUser.attributes.identities);
      urlParams.providerName = identifierData.providerName;
      urlParams.providerType = identifierData.providerType;
    } catch (error) {}
  }
  const urlParamsString = new URLSearchParams(urlParams).toString();

  let url;
  if (response.status === 200) {
    url = `${apiService.redirectOrigin}/Home/Authc?${urlParamsString}`;
  }
  return url;
};

const awsHelper = {
  getPostSignInRedirectUrl,
};

export default awsHelper;
