exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Tag__tag__rUU4q{display:-ms-inline-flexbox;display:inline-flex;overflow:hidden;-ms-flex-align:center;align-items:center;min-height:2.8rem;padding-left:.8rem;background-color:#dfe3e8;border-radius:3px;color:#212b36}.Tag__button__3hx8H{fill:#637381;-webkit-appearance:none;-moz-appearance:none;appearance:none;margin:0;padding:0;background:none;border:none;font-size:inherit;line-height:inherit;cursor:pointer;display:block;height:2.8rem;width:2.8rem;margin-left:.4rem;will-change:background,fill;transition-property:background,fill;transition-duration:.2s;transition-timing-function:cubic-bezier(.64,0,.35,1)}.Tag__button__3hx8H:focus{outline:none}.Tag__button__3hx8H:active,.Tag__button__3hx8H:focus,.Tag__button__3hx8H:hover{fill:#212b36;background:#c4cdd5}.Tag__button__3hx8H:active{background:rgba(145,158,171,.6)}", ""]);

// exports
exports.locals = {
	"tag": "Tag__tag__rUU4q",
	"button": "Tag__button__3hx8H"
};