exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Loading__loadingContainer__ygIo_{line-height:1rem}.Loading__loading__3rjoB{display:inline-block;animation:Loading__a__13GLd .5s linear infinite}@keyframes Loading__a__13GLd{to{transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"loadingContainer": "Loading__loadingContainer__ygIo_",
	"loading": "Loading__loading__3rjoB",
	"a": "Loading__a__13GLd"
};