exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DisplayText__displayText__3fhoH{margin:0}.DisplayText__sizeSmall__Sso0K{font-size:1.6rem;font-weight:400;line-height:2.4rem}@media (min-width:40em){.DisplayText__sizeSmall__Sso0K{font-size:2rem;line-height:2.4rem}}.DisplayText__sizeMedium__37xaN{font-size:2.1rem;font-weight:400;line-height:2.8rem}@media (min-width:40em){.DisplayText__sizeMedium__37xaN{font-size:2.6rem;line-height:3.2rem}}.DisplayText__sizeLarge__22K9D{font-size:2.4rem;font-weight:600;line-height:2.8rem}@media (min-width:40em){.DisplayText__sizeLarge__22K9D{font-size:2.8rem;line-height:3.2rem}}.DisplayText__sizeExtraLarge__2dT_i{font-size:2.7rem;font-weight:600;line-height:3.6rem}@media (min-width:40em){.DisplayText__sizeExtraLarge__2dT_i{font-size:4.2rem;line-height:4.4rem}}", ""]);

// exports
exports.locals = {
	"displayText": "DisplayText__displayText__3fhoH",
	"sizeSmall": "DisplayText__sizeSmall__Sso0K",
	"sizeMedium": "DisplayText__sizeMedium__37xaN",
	"sizeLarge": "DisplayText__sizeLarge__22K9D",
	"sizeExtraLarge": "DisplayText__sizeExtraLarge__2dT_i"
};