exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Popover__abovePopover__2XA3c{margin:.4rem 1.6rem 1.6rem}.Popover__abovePopover__2XA3c,.Popover__rightPopover__X7euy{opacity:1;box-shadow:0 0 0 1px rgba(6,44,82,.1),0 2px 16px rgba(33,43,54,.08);border-radius:3px;will-change:opacity,left,top;transition:opacity .2s cubic-bezier(.36,0,1,1)}.Popover__rightPopover__X7euy{margin:.4rem 1.6rem}.Popover__belowPopover__EXQ4m{margin:.4rem 1.6rem 1.6rem}.Popover__belowPopover__EXQ4m,.Popover__leftPopover__2HeAA{opacity:1;box-shadow:0 0 0 1px rgba(6,44,82,.1),0 2px 16px rgba(33,43,54,.08);border-radius:3px;will-change:opacity,left,top}.Popover__leftPopover__2HeAA{margin:1.6rem 1.6rem 1.6rem .4rem;transition:opacity .2s cubic-bezier(.36,0,1,1)}.Popover__measuring__3erRy{opacity:0}.Popover__positionedAbove__wB0Pk{margin:1.6rem 1.6rem .4rem}.Popover__positionedAbove__wB0Pk .Popover__tip__164qF{top:inherit;bottom:0}.Popover__light__31Mcr .Popover__wrapper__1EM9L{color:#fff}.Popover__light__31Mcr .Popover__tip__164qF,.Popover__light__31Mcr .Popover__wrapper__1EM9L{background:#212b36}.Popover__wrapper__1EM9L{display:-ms-flexbox;display:flex;background-color:#fff;color:#212b36;margin-top:1rem}.Popover__content__ZAAmh,.Popover__wrapper__1EM9L{position:relative;border-radius:3px}.Popover__content__ZAAmh{margin-top:.4rem}.Popover__tip__164qF{position:absolute;transform:translateX(-50%) rotate(45deg);height:1.4rem;width:1.4rem;background:#fff;box-shadow:0 0 0 1px rgba(6,44,82,.1),0 2px 16px rgba(33,43,54,.08);border-radius:2px}.Popover__label__38EXu{padding:0 .8rem .4rem}", ""]);

// exports
exports.locals = {
	"abovePopover": "Popover__abovePopover__2XA3c",
	"rightPopover": "Popover__rightPopover__X7euy",
	"belowPopover": "Popover__belowPopover__EXQ4m",
	"leftPopover": "Popover__leftPopover__2HeAA",
	"measuring": "Popover__measuring__3erRy",
	"positionedAbove": "Popover__positionedAbove__wB0Pk",
	"tip": "Popover__tip__164qF",
	"light": "Popover__light__31Mcr",
	"wrapper": "Popover__wrapper__1EM9L",
	"content": "Popover__content__ZAAmh",
	"label": "Popover__label__38EXu"
};