exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Caption__caption__3L1dq{font-size:1.3rem;font-weight:400;line-height:2rem;margin:0}@media (min-width:40em){.Caption__caption__3L1dq{font-size:1.2rem;line-height:1.6rem}}", ""]);

// exports
exports.locals = {
	"caption": "Caption__caption__3L1dq"
};