import React from "react";
import { Link, BodyText } from "engage-ui";

const TERMS_OF_SERVICE = "https://emgage.com/terms-and-conditions/";
const PRIVACY_POLICY = "https://emgage.com/privacy-policy-page/";

const Footer = ({ theme }) => {
  return (
    <BodyText componentClass={theme.Footer} componentColor="mid">
      By signing in, you agree to the{" "}
      <Link external url={TERMS_OF_SERVICE}>
        Terms of Service
      </Link>{" "}
      and{" "}
      <Link external url={PRIVACY_POLICY}>
        Privacy Policy
      </Link>
      .
    </BodyText>
  );
};

export default Footer;
