exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body .SideNavigation__li__3MKSi,body .SideNavigation__li__3MKSi:focus,body .SideNavigation__li__3MKSi:hover{color:#bdbdbd}.SideNavigation__accordionItem__5P3Tb .SideNavigation__header__2mzvl .SideNavigation__AccordianIcon__3PXic{fill:#bdbdbd}", ""]);

// exports
exports.locals = {
	"li": "SideNavigation__li__3MKSi",
	"accordionItem": "SideNavigation__accordionItem__5P3Tb",
	"header": "SideNavigation__header__2mzvl",
	"AccordianIcon": "SideNavigation__AccordianIcon__3PXic"
};