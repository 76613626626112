exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@keyframes Spinner__a__1XQZG{to{transform:rotate(1turn)}}.Spinner__spinner__3q8xb{animation:Spinner__a__1XQZG .5s linear infinite;color:transparent}.Spinner__sizeSmall__3FU90{height:2rem;width:2rem}.Spinner__sizeLarge__3_AzT{height:4.4rem;width:4.4rem}.Spinner__colorReverse__1DhqM{fill:#fff}.Spinner__colorNormal__29T3T{fill:#47c1bf}.Spinner__colorDisabled__3Yr7g{fill:#919eab}", ""]);

// exports
exports.locals = {
	"spinner": "Spinner__spinner__3q8xb",
	"a": "Spinner__a__1XQZG",
	"sizeSmall": "Spinner__sizeSmall__3FU90",
	"sizeLarge": "Spinner__sizeLarge__3_AzT",
	"colorReverse": "Spinner__colorReverse__1DhqM",
	"colorNormal": "Spinner__colorNormal__29T3T",
	"colorDisabled": "Spinner__colorDisabled__3Yr7g"
};