exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Accordion__accordionItem__1jGwe{transition:height .3s ease-in-out 0s;box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);background:#fff;margin:1.2rem!important;border-radius:.2rem;width:calc(100% - 24px)}.Accordion__accordionItem__1jGwe>.Accordion__header__29ePu{width:100%;font-size:1.3rem;font-weight:700;padding:1rem 1.2rem;position:relative;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.Accordion__accordionItem__1jGwe>.Accordion__header__29ePu .Accordion__AccordianIcon__2tudG{float:right;transform:rotate(0deg)}.Accordion__accordionItem__1jGwe>.Accordion__header__29ePu .Accordion__AccordianIcon__2tudG,.Accordion__accordionItem__1jGwe>.Accordion__header__29ePu.Accordion__active__2pJg6 .Accordion__AccordianIcon__2tudG{transition:all .3s ease-in-out;-moz-transition:all .3s ease-in-out;-webkit-transition:all .3s ease-in-out}.Accordion__accordionItem__1jGwe>.Accordion__header__29ePu.Accordion__active__2pJg6 .Accordion__AccordianIcon__2tudG{transform:rotate(180deg)}.Accordion__accordionItem__1jGwe>.Accordion__body__nJ3yf{padding:1rem;opacity:1;overflow-y:hidden;overflow-x:auto;transition:all .2s}.Accordion__accordionItem__1jGwe>.Accordion__bodyCollapsed__3X78s{height:0;opacity:0;transition:all .2s}.Accordion__accordionItem__1jGwe label{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"accordionItem": "Accordion__accordionItem__1jGwe",
	"header": "Accordion__header__29ePu",
	"AccordianIcon": "Accordion__AccordianIcon__2tudG",
	"active": "Accordion__active__2pJg6",
	"body": "Accordion__body__nJ3yf",
	"bodyCollapsed": "Accordion__bodyCollapsed__3X78s"
};