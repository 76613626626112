exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Alert__alert__9eS9Y{position:relative;margin-bottom:2rem;padding:1.5rem 3rem 1.5rem 1.5rem;background:#f9fafb;color:#000}*+alert{margin-top:2rem}.Alert__alert__9eS9Y>:last-child{margin-bottom:0}.Alert__alertClose__2xzdG{position:absolute;top:1.5rem;right:0;opacity:.4;margin:0;border:none;border-radius:0;overflow:visible;font:inherit;color:inherit;text-transform:none;padding:0;background-color:transparent;display:inline-block;fill:currentcolor;line-height:2;height:1.2rem;width:3.2rem;transition:.1s ease-in-out;transition-property:color,opacity}.Alert__alertClose__2xzdG:first-child+*{margin-top:0}.Alert__alertClose__2xzdG:focus,.Alert__alertClose__2xzdG:hover{color:inherit;opacity:.8}.Alert__alertPrimary__3loxY{background:#ebf5fa;color:#007ace}.Alert__alertSuccess__2mW6h{background:#e3f1df;color:#173630}.Alert__alertWarning__1mkh7{background:#fcebdb;color:#c05717}.Alert__alertDanger__1Nysq{background:#fbeae5;color:#bf0711}.Alert__alert__9eS9Y h1,.Alert__alert__9eS9Y h2,.Alert__alert__9eS9Y h3,.Alert__alert__9eS9Y h4,.Alert__alert__9eS9Y h5,.Alert__alert__9eS9Y h6{color:inherit}.Alert__alert__9eS9Y a:not([class]),.Alert__alert__9eS9Y a:not([class]):hover{color:inherit;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"alert": "Alert__alert__9eS9Y",
	"alertClose": "Alert__alertClose__2xzdG",
	"alertPrimary": "Alert__alertPrimary__3loxY",
	"alertSuccess": "Alert__alertSuccess__2mW6h",
	"alertWarning": "Alert__alertWarning__1mkh7",
	"alertDanger": "Alert__alertDanger__1Nysq"
};