exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Card__card__tRKnc{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-width:0;word-wrap:break-word;background-color:#fff;box-shadow:0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);background-clip:border-box;border-radius:.25rem}.Card__card__tRKnc+.Card__card__tRKnc{margin-top:2rem}@media (min-width:46.5em){.Card__card__tRKnc{border-radius:3px}}.Card__subdued__22Oa-{background-color:#f9fafb}.Card__header__3N45G{padding:1rem 2rem;margin-bottom:0;border-bottom:1px solid rgba(0,0,0,.125);font-stretch:normal;font-style:normal;line-height:1.23;letter-spacing:normal;background-color:transparent}.Card__body__34s8y{-ms-flex:1 1 auto;flex:1 1 auto;padding:1.25rem}.Card__section__1CDqA{padding:2rem}.Card__section__1CDqA+.Card__section__1CDqA{border-top:1px solid #dfe3e8}.Card__section-subdued__1ncNz{background-color:#f9fafb}.Card__sectionHeader__28x-T{padding-bottom:.8rem}.Card__footer__3pwql{display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end;padding:0 2rem 2rem}.Card__cardTitle__3NqNJ{letter-spacing:0;margin-bottom:2rem;border-bottom:1px solid rgba(0,0,0,.12)}", ""]);

// exports
exports.locals = {
	"card": "Card__card__tRKnc",
	"subdued": "Card__subdued__22Oa-",
	"header": "Card__header__3N45G",
	"body": "Card__body__34s8y",
	"section": "Card__section__1CDqA",
	"section-subdued": "Card__section-subdued__1ncNz",
	"sectionHeader": "Card__sectionHeader__28x-T",
	"footer": "Card__footer__3pwql",
	"cardTitle": "Card__cardTitle__3NqNJ"
};