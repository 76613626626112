import Modal from './Modal';
import ModalContent from './ModalContent';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

export { Props } from './Modal';
export {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter
};
