exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PositionedOverlay__PositionedOverlay__5EzY2{position:absolute;z-index:500}.PositionedOverlay__fixed__2SyyS{position:fixed}.PositionedOverlay__calculating__2Is2o{visibility:hidden}", ""]);

// exports
exports.locals = {
	"PositionedOverlay": "PositionedOverlay__PositionedOverlay__5EzY2",
	"fixed": "PositionedOverlay__fixed__2SyyS",
	"calculating": "PositionedOverlay__calculating__2Is2o"
};