exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Heading__heading__1p7gP{font-size:1.7rem;font-weight:600;line-height:2.4rem;margin:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}@media (min-width:40em){.Heading__heading__1p7gP{font-size:1.6rem}}.Heading__sizeH1__1tbHp{font-size:4rem;line-height:4rem}.Heading__sizeH2__2HaYB{font-size:3.2rem;line-height:3.6rem}.Heading__sizeH3__3gHhP{font-size:2.4rem;line-height:2.8rem}.Heading__sizeH4__3nOaZ,.Heading__sizeSubtitle__34l0Q{font-size:1.6rem;line-height:2.4rem}@media (min-width:40em){.Heading__sizeSubtitle__34l0Q{font-size:1.6rem;line-height:2.4rem}}", ""]);

// exports
exports.locals = {
	"heading": "Heading__heading__1p7gP",
	"sizeH1": "Heading__sizeH1__1tbHp",
	"sizeH2": "Heading__sizeH2__2HaYB",
	"sizeH3": "Heading__sizeH3__3gHhP",
	"sizeH4": "Heading__sizeH4__3nOaZ",
	"sizeSubtitle": "Heading__sizeSubtitle__34l0Q"
};