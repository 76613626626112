exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Subheading__subheading__1xK2e{font-size:1.3rem;font-weight:700;line-height:1.6rem;text-transform:uppercase;margin:0}@media (min-width:40em){.Subheading__subheading__1xK2e{font-size:1.2rem}}", ""]);

// exports
exports.locals = {
	"subheading": "Subheading__subheading__1xK2e"
};