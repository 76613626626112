exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Message__messagePrompt__1sUZe{background:#c62828;color:#fff}.Message__messagePrompt__1sUZe:after{border-color:#c62828;border-left-color:transparent;border-right-color:transparent}", ""]);

// exports
exports.locals = {
	"messagePrompt": "Message__messagePrompt__1sUZe"
};