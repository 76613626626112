import * as React from "react";
import { FlexBox } from "engage-ui";
import helpers from "helpers";
import images from "data/images";

const EmgageSpinner = (props) => {
  const {
    componentClass = "",
    componentStyle = {},
    spinnerText,
    theme,
  } = props;
  const spinnerClass = helpers.classNames(componentClass, theme.EmgageSpinner);

  return (
    <div className={spinnerClass} style={componentStyle}>
      <FlexBox direction="Column">
        <img src={images.eSpinner} alt="eSpinner" />
        <img
          src={images.eSpinnerOrbit}
          alt="eSpinnerOrbit"
          className={theme.eSpinner}
        />
        {spinnerText && (
          <small className={theme.spinnerText}>{spinnerText}</small>
        )}
      </FlexBox>
    </div>
  );
};

export default EmgageSpinner;
