exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Avatar__avatar__3TmQW{position:relative;display:-ms-flexbox;display:flex;overflow:hidden;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;background:#47c1bf;border-radius:50%;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.Avatar__sizeSmall__1LNqL{height:3.2rem;width:3.2rem;font-size:1.4rem}.Avatar__sizeMedium__BuFH_{height:4rem;width:4rem;font-size:1.8rem}.Avatar__sizeLarge__Z3KjQ{height:6rem;width:6rem;font-size:2.6rem}.Avatar__styleOne__2-60v{background:#47c1bf}.Avatar__styleTwo__23zWE{background:#ed6347}.Avatar__styleThree__1GMUV{background:#f49342}.Avatar__styleFour__1I1UU{background:#50b83c}.Avatar__styleFive__rkWHg{background:#007ace}.Avatar__styleSix__reRp6{background:#9c6ade}.Avatar__hasImage__3C3Fh{background:transparent}.Avatar__image__2090h{width:100%}.Avatar__initials__RWDlN{line-height:0;color:#fff;text-transform:uppercase}", ""]);

// exports
exports.locals = {
	"avatar": "Avatar__avatar__3TmQW",
	"sizeSmall": "Avatar__sizeSmall__1LNqL",
	"sizeMedium": "Avatar__sizeMedium__BuFH_",
	"sizeLarge": "Avatar__sizeLarge__Z3KjQ",
	"styleOne": "Avatar__styleOne__2-60v",
	"styleTwo": "Avatar__styleTwo__23zWE",
	"styleThree": "Avatar__styleThree__1GMUV",
	"styleFour": "Avatar__styleFour__1I1UU",
	"styleFive": "Avatar__styleFive__rkWHg",
	"styleSix": "Avatar__styleSix__reRp6",
	"hasImage": "Avatar__hasImage__3C3Fh",
	"image": "Avatar__image__2090h",
	"initials": "Avatar__initials__RWDlN"
};