import React, { useEffect, useState } from "react";
import App from "containers/App";
import LoginCallback from "containers/LoginCallback";
import Logout from "containers/Logout";
import { Switch, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsconfig from "config/aws";
import apiService from "services/api.service";
import { Spinner } from "engage-ui";

const MainRoutes = () => {
  const [isDataInitialized, setDataInitialized] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect_url =
      urlParams.get("redirect_url") || apiService.redirectUrl;
    const client_id = urlParams.get("client_id") || apiService.clientId;

    let redirect_origin;
    if (redirect_url) {
      try {
        const urlObject = new URL(redirect_url);
        redirect_origin = urlObject.origin;
      } catch (error) {
      }
    }

    if (!redirect_url || !client_id || !redirect_origin) {
      setError("URL parameters are missing");
      return;
    } else {
      apiService.setRedirectUrl(redirect_url);
      apiService.setClientId(client_id);
      apiService.setRedirectOrigin(redirect_origin);
    }

    const updatedConfig = {
      ...awsconfig,
      aws_user_pools_web_client_id: client_id,
      oauth: {
        ...awsconfig.oauth,
        redirectSignIn: `${window.location.origin}/login-callback/`,
        redirectSignOut: `${window.location.origin}/`,
      },
    };
    Amplify.configure(updatedConfig);
    setDataInitialized(true);
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return isDataInitialized ? (
    <Switch>
      <Route path="/" exact component={App} />
      <Route path="/login-callback" component={LoginCallback} />
      <Route path="/logout" component={Logout} />
    </Switch>
  ) : (
    <Spinner>Loading</Spinner>
  );
};

export default MainRoutes;
