// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwoFA_TwoFA__dIihD .TwoFA_headingTitle__Gi70x {
  margin: 2rem 0; }

.TwoFA_TwoFA__dIihD .TwoFA_inputTypeRow__SOhAh {
  margin-bottom: 1.6rem; }

.TwoFA_TwoFA__dIihD .TwoFA_confirmInButton__P0aAm {
  margin-bottom: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/TwoFA/TwoFA.module.scss"],"names":[],"mappings":"AAAA;EAEQ,cAAc,EAAA;;AAFtB;EAKQ,qBAAqB,EAAA;;AAL7B;EAQQ,mBAAmB,EAAA","sourcesContent":[".TwoFA {\n    .headingTitle{\n        margin: 2rem 0;\n    }\n    .inputTypeRow {\n        margin-bottom: 1.6rem;\n    }\n    .confirmInButton {\n        margin-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TwoFA": `TwoFA_TwoFA__dIihD`,
	"headingTitle": `TwoFA_headingTitle__Gi70x`,
	"inputTypeRow": `TwoFA_inputTypeRow__SOhAh`,
	"confirmInButton": `TwoFA_confirmInButton__P0aAm`
};
export default ___CSS_LOADER_EXPORT___;
