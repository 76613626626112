exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Banner__banner__AwQEM{background-color:#fafafa;border:1px solid #757575;position:relative;display:-ms-flexbox;display:flex;padding:1.6rem;border-radius:.8rem;transition:box-shadow .2s cubic-bezier(.64,0,.35,1);fill:#616161}.Banner__banner__AwQEM:focus{box-shadow:inset 1px 0 0 0 #757575,inset 0 0 0 1px #757575,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)}.Banner__banner__AwQEM+.Banner__Banner__3niHt{margin-top:2rem}.Banner__banner__AwQEM:focus{outline:none}.Banner__statusSuccess__1-2OK{background-color:#f1f8e9;border:1px solid #43a047;fill:#388e3c}.Banner__statusSuccess__1-2OK:focus{box-shadow:inset 1px 0 0 0 #43a047,inset 0 0 0 1px #43a047,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)}.Banner__statusInfo__3-En4{background-color:#e1f5fe;border:1px solid #039be5;fill:#0288d1}.Banner__statusInfo__3-En4:focus{box-shadow:inset 1px 0 0 0 #039be5,inset 0 0 0 1px #039be5,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)}.Banner__statusWarning__1QpxD{background-color:#fffde7;border:1px solid #fdd835;fill:#fbc02d}.Banner__statusWarning__1QpxD:focus{box-shadow:inset 1px 0 0 0 #fdd835,inset 0 0 0 1px #fdd835,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)}.Banner__statusCritical__1Rz0Q{background-color:#ffebee;border:1px solid #e53935;fill:#d32f2f}.Banner__statusCritical__1Rz0Q:focus{box-shadow:inset 1px 0 0 0 #e53935,inset 0 0 0 1px #e53935,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)}", ""]);

// exports
exports.locals = {
	"banner": "Banner__banner__AwQEM",
	"Banner": "Banner__Banner__3niHt",
	"statusSuccess": "Banner__statusSuccess__1-2OK",
	"statusInfo": "Banner__statusInfo__3-En4",
	"statusWarning": "Banner__statusWarning__1QpxD",
	"statusCritical": "Banner__statusCritical__1Rz0Q"
};