import { Auth } from "aws-amplify";
import EmgageSpinner from "components/EmgageSpinner";
import { Heading } from "engage-ui";
import awsHelper from "helpers/aws.helper";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const LoginCallback = () => {
  const history = useHistory();
  const [error, setError] = useState();
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (authData) => {
        const redirectUrl = await awsHelper.getPostSignInRedirectUrl(authData);
        if (redirectUrl) {
          window.location = redirectUrl;
        } else {
          setError("something went wrong");
        }
      })
      .catch((error) => {
        history.push("/");
      });
  }, [history]);

  if (error) {
    return <Heading headingSize="subtitle">{error}</Heading>;
  }

  return (
    <div
      style={{
        height: "100vh",
        background: "linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%)",
      }}
    >
      <EmgageSpinner spinnerText="Completing Sign In."></EmgageSpinner>;
    </div>
  );
};

export default LoginCallback;
