exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DisplayText__displayText__3o8rn{margin:0}.DisplayText__sizeSmall__3N-Gx{font-size:1.6rem;font-weight:400;line-height:2.4rem}@media (min-width:40em){.DisplayText__sizeSmall__3N-Gx{font-size:2rem;line-height:2.4rem}}.DisplayText__sizeMedium__2v4PJ{font-size:2.1rem;font-weight:400;line-height:2.8rem}@media (min-width:40em){.DisplayText__sizeMedium__2v4PJ{font-size:2.6rem;line-height:3.2rem}}.DisplayText__sizeLarge__3K3ov{font-size:2.4rem;font-weight:600;line-height:2.8rem}@media (min-width:40em){.DisplayText__sizeLarge__3K3ov{font-size:2.8rem;line-height:3.2rem}}.DisplayText__sizeExtraLarge__1yum4{font-size:2.7rem;font-weight:600;line-height:3.6rem}@media (min-width:40em){.DisplayText__sizeExtraLarge__1yum4{font-size:4.2rem;line-height:4.4rem}}", ""]);

// exports
exports.locals = {
	"displayText": "DisplayText__displayText__3o8rn",
	"sizeSmall": "DisplayText__sizeSmall__3N-Gx",
	"sizeMedium": "DisplayText__sizeMedium__2v4PJ",
	"sizeLarge": "DisplayText__sizeLarge__3K3ov",
	"sizeExtraLarge": "DisplayText__sizeExtraLarge__1yum4"
};