exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Scrollable__scrollable__29r17{-webkit-overflow-scrolling:touch}.Scrollable__horizontal___Ohq0{overflow-x:auto}.Scrollable__vertical__Dh6ND{overflow-y:auto}.Scrollable__hasTopShadow__w1LCa{box-shadow:inset 0 2rem 2rem -2rem rgba(0,0,0,.1)}.Scrollable__hasBottomShadow__1e3pj{box-shadow:inset 0 -2rem 2rem -2rem rgba(0,0,0,.1)}.Scrollable__hasTopShadow__w1LCa.Scrollable__hasBottomShadow__1e3pj{box-shadow:inset 0 2rem 2rem -2rem rgba(0,0,0,.1),inset 0 -2rem 2rem -2rem rgba(0,0,0,.1)}", ""]);

// exports
exports.locals = {
	"scrollable": "Scrollable__scrollable__29r17",
	"horizontal": "Scrollable__horizontal___Ohq0",
	"vertical": "Scrollable__vertical__Dh6ND",
	"hasTopShadow": "Scrollable__hasTopShadow__w1LCa",
	"hasBottomShadow": "Scrollable__hasBottomShadow__1e3pj"
};