exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Modal__overlay__2iBZS{background:rgba(0,0,0,.2)}.Modal__dialog__1wZF7{border-radius:.8rem;border:1px solid #ccc;box-shadow:0 1px 5px rgba(0,0,0,.3);max-width:calc(100% - 50px)}.Modal__header__18T20{border-radius:.8rem .8rem 0 0}.Modal__footer__1ahj9{border-radius:0 0 .8rem .8rem}", ""]);

// exports
exports.locals = {
	"overlay": "Modal__overlay__2iBZS",
	"dialog": "Modal__dialog__1wZF7",
	"header": "Modal__header__18T20",
	"footer": "Modal__footer__1ahj9"
};