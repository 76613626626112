import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Banner, BodyText, Card, CardBody, FlexBox, Heading } from "engage-ui";
import images from "data/images";
import SocialSignIn from "components/SocialSignIn";
import SignIn from "components/SignIn";
import apiService from "services/api.service";
import TwoFA from "components/TwoFA";
import Footer from "components/Footer";
import ForgotPassword from "components/ForgotPassword";

const ACTIVE_STEPS = {
  SIGN_IN: "SIGN_IN",
  MFA: "MFA",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
};
const App = ({ theme }) => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(ACTIVE_STEPS.SIGN_IN);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);

  const [error, setError] = useState();
  const [apiError, setApiError] = useState();
  const [loginPageConfig, setLoginPageConfig] = useState();

  const mfaType = cognitoUser?.challengeName;

  useEffect(() => {
    if (mfaType) {
      setActiveStep(ACTIVE_STEPS.MFA);
    } else {
      setActiveStep(ACTIVE_STEPS.SIGN_IN);
    }
  }, [mfaType]);

  useEffect(() => {
    const setData = async () => {
      if (cognitoUser) {
        if (!cognitoUser.challengeName) {
          if (rememberMe) {
            await Auth.rememberDevice();
          } else {
            await Auth.forgetDevice();
          }
          history.push("/login-callback");
        }
        return;
      }

      if (apiError) {
        setApiError();
      }
    };
    setData();
    // eslint-disable-next-line
  }, [cognitoUser, rememberMe]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        history.push("login-callback");
      })
      .catch(async (error) => {
        console.log(error);
        // if user is not logged in then get login page info data.
        apiService
          .getLoginPageConfig()
          .then((response) => {
            const responseLoginPageConfig = response.data;
            responseLoginPageConfig.providers =
              responseLoginPageConfig.providers || [];
            setLoginPageConfig(responseLoginPageConfig);
          })
          .catch((error) => {
            setError("Invalid redirect URL");
          });
      });
  }, [history]);

  if (error) {
    return <BodyText>{error}</BodyText>;
  }
  if (!loginPageConfig) {
    return null;
  }

  const apiErrorComponent = apiError && (
    <Banner
      theme={theme}
      status="critical"
      componentTitle={apiError}
      icon="alert"
    />
  );

  const signInComponent = activeStep === ACTIVE_STEPS.SIGN_IN && (
    <>
      <Heading componentClass={theme.headingTitle} headingSize="h2">
        Sign in
      </Heading>
      {apiErrorComponent}
      {loginPageConfig.emgageUserLoginEnabled && (
        <SignIn
          rememberMe={rememberMe}
          setRememberMe={setRememberMe}
          setApiError={setApiError}
          setCognitoUser={setCognitoUser}
          onForgotPassword={() => setActiveStep(ACTIVE_STEPS.FORGOT_PASSWORD)}
        />
      )}
      {loginPageConfig.emgageUserLoginEnabled &&
        loginPageConfig.providers.length > 0 && (
          <div className={theme.separator}></div>
        )}
      <SocialSignIn providers={loginPageConfig.providers} />
    </>
  );

  const forgotPasswordComponent = activeStep ===
    ACTIVE_STEPS.FORGOT_PASSWORD && (
    <>
      <Heading componentClass={theme.headingTitle} headingSize="h2">
        Forgot Password
      </Heading>
      {apiErrorComponent}
      <ForgotPassword
        setApiError={setApiError}
        setCognitoUser={setCognitoUser}
        onClose={() => {
          setApiError();
          setActiveStep(ACTIVE_STEPS.SIGN_IN);
        }}
      />
    </>
  );

  return (
    <FlexBox
      componentClass={theme.App}
      align="Center"
      justify="Center"
      direction="Column"
    >
      <div className={theme.content}>
        <FlexBox
          direction="Row"
          componentClass={theme.contentSections}
          wrap="Wrap"
          align="Stretch"
          justify="Center"
        >
          <FlexBox direction="Column" componentClass={theme.welcomeSection}>
            <img
              alt="emgageLogo"
              src={images.emgageLogo}
              style={{ height: "75px" }}
            />
            <BodyText componentColor="text" componentSize="small">
              A Cyber-Secure&trade; App Platform for All.
            </BodyText>
            <img
              className={theme.emgageSplashImage}
              src={images.emgageSplashImage}
              alt="emgageSplashImage"
            />
          </FlexBox>
          <Card componentClass={theme.card}>
            <FlexBox direction="Column">
              <CardBody componentClass={theme.cardBody}>
                {signInComponent}
                {forgotPasswordComponent}
                {activeStep === ACTIVE_STEPS.MFA && (
                  <TwoFA
                    rememberMe={rememberMe}
                    apiErrorComponent={apiErrorComponent}
                    setApiError={setApiError}
                    user={cognitoUser}
                    onCancel={() => setCognitoUser(null)}
                  />
                )}

                <Footer />
              </CardBody>
            </FlexBox>
          </Card>
        </FlexBox>
      </div>
    </FlexBox>
  );
};

export default App;
