import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@friendsofreactjs/react-css-themr";
import reportWebVitals from "./reportWebVitals";
import MainRoutes from "routes";
import 'engage-ui/lib/DeliciousTheme';
import "./index.css";

const MOUNT_NODE = document.getElementById("root");

const renderApp = (RootComponent, staticAppData) => {
  const theme = window?.DeliciousTheme?.DeliciousThemeContext;
  render(
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={"/"}>
        <RootComponent />
      </BrowserRouter>
    </ThemeProvider>,
    MOUNT_NODE
  );
};

renderApp(MainRoutes);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//Testing 123
reportWebVitals();
