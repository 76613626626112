exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InlineError__InlineError__8ii09{display:-ms-flexbox;display:flex;color:var(--p-text-critical,\"red\");fill:var(--p-icon-critical,\"red\")}.InlineError__Icon__3uJGc{fill:currentColor;margin-left:-.2rem;margin-right:.2rem}", ""]);

// exports
exports.locals = {
	"InlineError": "InlineError__InlineError__8ii09",
	"Icon": "InlineError__Icon__3uJGc"
};