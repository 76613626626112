import { useEffect } from "react";
const useEnterEvent = (listener) => {
  useEffect(() => {
    const listenerWrapper = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        listener(event);
      }
    };

    document.addEventListener("keydown", listenerWrapper);
    return () => {
      document.removeEventListener("keydown", listenerWrapper);
    };
    // eslint-disable-next-line
  }, []);
};

export default useEnterEvent;
