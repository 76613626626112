exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Label__label__28y05{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:baseline;align-items:baseline;-webkit-tap-highlight-color:transparent}.Label__hidden__oXpA_{position:absolute!important;top:0;clip:rect(1px,1px,1px,1px)!important;overflow:hidden!important;height:1px!important;width:1px!important;padding:0!important;border:0!important}.Label__text__36vss{font-size:1.4rem;font-weight:400;line-height:2rem;text-transform:none;letter-spacing:normal;display:block;-ms-flex:1 1 auto;flex:1 1 auto;color:currentColor;-webkit-tap-highlight-color:transparent}@media (min-width:40em){.Label__text__36vss{font-size:1.4rem}}", ""]);

// exports
exports.locals = {
	"label": "Label__label__28y05",
	"hidden": "Label__hidden__oXpA_",
	"text": "Label__text__36vss"
};