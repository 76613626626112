exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".VisuallyHidden__visuallyHidden__14gpv{position:absolute!important;top:0;clip:rect(1px,1px,1px,1px)!important;overflow:hidden!important;height:1px!important;width:1px!important;padding:0!important;border:0!important;color:#000;background-color:#fff}", ""]);

// exports
exports.locals = {
	"visuallyHidden": "VisuallyHidden__visuallyHidden__14gpv"
};