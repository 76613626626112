import React, { useState } from "react";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { Button, FlexBox, Icon, TextField, Checkbox } from "engage-ui";
import { Formik } from "formik";
import useEnterEvent from "hooks/useEnterEvent";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .email("Work email must be a valid email")
    .required("Work email is required"),
  password: Yup.string().min(2).required("Password is required"),
});

const SignIn = ({
  theme,
  setApiError,
  setCognitoUser,
  rememberMe,
  setRememberMe,
  onForgotPassword,
}) => {
  const [readOnly, setReadOnly] = useState(true);
  const formicRef = React.useRef();

  const listenEnterEvent = () => {
    if (!formicRef.current.isSubmitting) {
      formicRef.current.handleSubmit();
    }
  };
  useEnterEvent(listenEnterEvent);

  React.useEffect(() => {
    setTimeout(() => {
      setReadOnly(false);
    }, 500);
  }, []);
  
  const singIn = (values, { setSubmitting }) => {
    let data = { ...values };
    data.username = data.username.toLowerCase();
    Auth.signIn(data)
      .then((user) => {
        setApiError();
        setCognitoUser(user);
      })
      .catch((error) => {
        setSubmitting(false);
        setApiError(error.message);
        console.log(error.code, error.message);
      });
  };

  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        singIn(values, { setSubmitting });
      }}
      innerRef={formicRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <FlexBox direction="Column" componentClass={theme.SignIn}>
          <div className={theme.inputTypeRow}>
            <TextField
              type="text"
              name="username"
              onChange={(_v, e) => handleChange(e)}
              onBlur={handleBlur}
              value={values.username}
              errors={touched.username && errors.username && [errors.username]}
              placeholder="Work email"
              placeholderAlign="left"
              prefix={<Icon source="envelope" componentColor="inkLighter" />}
            />
          </div>
          <div className={theme.inputTypeRow}>
            <TextField
              type="password"
              name="password"
              onChange={(_v, e) => handleChange(e)}
              onBlur={handleBlur}
              value={values.password}
              errors={touched.password && errors.password && [errors.password]}
              placeholder="Password"
              placeholderAlign="left"
              prefix={<Icon source="lock" componentColor="inkLighter" />}
              readOnly={readOnly}
            />
          </div>
          <FlexBox componentClass={theme.inputTypeRow} direction="Row" justify="SpaceBetween" align="Center">
          <div>
            <Checkbox
              name="rememberMe"
              checked={rememberMe}
              label={"Remember me"}
              onChange={(val) => setRememberMe(val)}
            />
          </div>
          <Button plain onClick={onForgotPassword} >
            Forgot password?
          </Button>
          </FlexBox>
          <Button
            componentClass={theme.signInButton}
            primary
            disabled={isSubmitting}
            onClick={handleSubmit}
            title="Sign In"
            componentSize="large"
          >
            Sign In
          </Button>
        </FlexBox>
      )}
    </Formik>
  );
};

export default SignIn;
