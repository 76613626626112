exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NoData__NoData__2MDYn{text-align:center}.NoData__NoData__2MDYn .NoData__icon__Szcs6{display:inline-block;width:3.6rem;height:2.4rem}.NoData__NoData__2MDYn .NoData__icon__Szcs6 .NoData__svg__3DT-6{max-width:none;max-height:none}.NoData__NoData__2MDYn .NoData__bodyText__2nxCu{margin-top:1.2rem}", ""]);

// exports
exports.locals = {
	"NoData": "NoData__NoData__2MDYn",
	"icon": "NoData__icon__Szcs6",
	"svg": "NoData__svg__3DT-6",
	"bodyText": "NoData__bodyText__2nxCu"
};