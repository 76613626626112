import { Auth } from "aws-amplify";
import { Spinner } from "engage-ui";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const history = useHistory();
  useEffect(() => {
    Auth.signOut()
      .then(() => {
        history.push({
          pathname: "/",
          search: new URLSearchParams(window.location.search).toString(),
        });
      })
      .catch((error) => {
      });
  }, [history]);

  return <Spinner>Please wait</Spinner>;
};

export default Logout;
