exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Spinner__sizeSmall__1kPuD{height:2rem;width:2rem}.Spinner__sizeLarge__3SulV{height:4.4rem;width:4.4rem}.Spinner__colorReverse__pshvi{fill:#fff}.Spinner__colorNormal__1_udB{fill:#455a64}.Spinner__colorDisabled__1IqXu{fill:#9e9e9e}", ""]);

// exports
exports.locals = {
	"sizeSmall": "Spinner__sizeSmall__1kPuD",
	"sizeLarge": "Spinner__sizeLarge__3SulV",
	"colorReverse": "Spinner__colorReverse__pshvi",
	"colorNormal": "Spinner__colorNormal__1_udB",
	"colorDisabled": "Spinner__colorDisabled__1IqXu"
};