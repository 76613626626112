exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Message__messageBlock__1xsp3{margin:0 0 1.3rem}.Message__messagePrompt__3OA9T{display:inline-block;position:relative;box-sizing:border-box;padding:.5rem .8rem;background:#bf0711;color:#fff;font-size:.8125em;line-height:1.2em;text-align:center}.Message__messagePrompt__3OA9T:after{content:\"\";display:block;position:absolute;width:0;height:0;bottom:-.5rem;border:5px solid #bf0711;border-bottom:0;border-left-color:transparent;border-right-color:transparent}", ""]);

// exports
exports.locals = {
	"messageBlock": "Message__messageBlock__1xsp3",
	"messagePrompt": "Message__messagePrompt__3OA9T"
};