export const ACCORDION = 'PAccordtion';
export const ALERT = 'PAlert';
export const AVATAR = 'PAvatar';
export const BADGE = 'PBadge';
export const ENTITYSTATE = 'PEntityState';
export const BANNER = 'PBanner';
export const BUTTON = 'PButton';
export const BUTTON_GROUP = 'PButtonGroup';
export const BODY_TEXT = 'PBodyText';
export const TOGGLEBUTTON_GROUP = 'PToggleButtonGroup';
export const CARD = 'PCard';
export const CHECKBOX = 'PCheckbox';
export const CHIP = 'PChip';
export const CLICKABLECHIP = 'PClickableChip';
export const CHOICE = 'PChoice';
export const CHOICE_LIST = 'PChoiceList';
export const COLUMN = 'PColumn';
export const CONNECTED = 'PConnected';
export const DATEPICKER = 'PDatePicker';
export const DATETIMEPICKER = 'PDateTimePicker';
export const DISPLAY_TEXT = 'PDisplayText';
export const FLEXBOX = 'PFlexBox';
export const FORM_LAYOUT = 'PFormLayout';
export const HEADING = 'Heading';
export const GRID = 'PGrid';
export const ICON = 'PIcon';
export const LABEL = 'PLabel';
export const LABELLED = 'PLabelled';
export const LINK = 'PLink';
export const LIST = 'PList';
export const DESCRIPTIONLIST = 'PDESCRIPTIONLIST';
export const LOADING = 'PLoading';
export const MASK_TEXT_FIELD = 'PMaskTextField';
export const MESSAGE = 'PMessage';
export const PANEL = 'PPanel';
export const PICKER = 'PPicker';
export const POPOVERPICKER = 'PPopoverPicker';
export const DROPDOWN = 'PDropdown';
export const DROPDOWNITEM = 'PDropdownItem';
export const POSITIONED_OVERLAY = 'PPositionedOverlay';
export const POPOVER = 'PPOPOVER';
export const RADIO_BUTTON = 'PRadioButton';
export const SCROLLABLE = 'PScrollable';
export const SELECT = 'PSelect';
export const STACK = 'PStack';
export const SUBHEADING = 'PSubheading';
export const TAG = 'PTag';
export const TEXT_FIELD = 'PTextField';
export const TOOLTIP = 'PTooltip';
export const VIDEO = 'PVideo';
export const VISUALLY_HIDDEN = 'PVisuallyHidden';
export const MODAL = 'PModal';
export const CAPTION = 'PCaption';
export const SPINNER = 'PSpinner';
export const SIDENAVIGATION = 'PSideNavigation';
export const TABLE = 'PTable';
export const DRAWER = 'PDrawer';
export const DRAWERCONTENT = 'PDrawerContent';
export const TAB = 'PTab';
export const TREEVIEW = 'PTreeview';
export const BREADCRUMB = 'PBreadcrumb';
export const COMBOBOX = 'PComboBox';
export const STICKY = 'PSticky';
export const PROCESS = 'PProcess';
export const PAGINATION = 'PPagination';
export const PAGER = 'PPager';
export const PAGESIZE = 'PPageSize';
export const SWITCHCHECKBOX = 'PSwitchCheckbox';
export const AUTOSUGGEST = 'PAutosuggest';
export const SEARCH_HELPER = 'PSearchHelper';
export const NODATA = 'PNoData';
export const SEGMENT = 'PSegment';
export const MYDROPZONE = 'PMyDropzone';
