import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import { Button, FlexBox, Icon, TextField } from "engage-ui";
import { Formik } from "formik";
import apiService from "services/api.service";
import useEnterEvent from "hooks/useEnterEvent";

const ForgotPassword = ({ theme, setApiError, onClose }) => {
  const [readOnly, setReadOnly] = useState(true);
  const [email, setEmail] = useState("");
  const formicRef = React.useRef();
  const listenEnterEvent = () => {
    if (!formicRef.current.isSubmitting) {
      formicRef.current.handleSubmit();
    }
  };
  useEnterEvent(listenEnterEvent);

  React.useEffect(() => {
    setTimeout(() => {
      setReadOnly(false);
    }, 500);
  }, []);

  const validationSchema = useMemo(() => {
    if (email) {
      return Yup.object().shape({
        email: Yup.string()
          .email("Work email must be a valid email")
          .required("Work email is required"),
        code: Yup.string()
          .matches(/^[0-9]+$/, "Verification code must be only digits")
          .required("Verification code is required")
          .test(
            "len",
            "Verification code must be exactly 6 characters",
            (val) => (val || "").length === 6
          ),
        password: Yup.string().min(2).required("Password is required"),
      });
    } else {
      return Yup.object().shape({
        email: Yup.string()
          .email("Work email must be a valid email")
          .required("Work email is required"),
      });
    }
  }, [email]);

  const resetPassword = (values, { setSubmitting }) => {
    apiService
      .confirmResetPassword(email, values.code, values.password)
      .then(function () {
        onClose();
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.userMessage;
        setApiError(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const forgotPassword = (data, { setSubmitting }) => {
    const email = data.email.toLowerCase();
    apiService
      .resetPassword(email)
      .then(() => {
        setEmail(email);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.userMessage;
        setApiError(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{ email: "", password: "", code: "" }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setFieldTouched }) => {
        if (email) {
          resetPassword(values, { setSubmitting });
        } else {
          setFieldTouched("code", false);
          setFieldTouched("password", false);
          forgotPassword(values, { setSubmitting });
        }
      }}
      innerRef={formicRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <FlexBox direction="Column" componentClass={theme.ForgotPassword}>
          <div className={theme.inputTypeRow}>
            <TextField
              type="text"
              name="email"
              onChange={(_v, e) => handleChange(e)}
              onBlur={handleBlur}
              value={values.email}
              errors={touched.email && errors.email && [errors.email]}
              placeholder="Work email"
              placeholderAlign="left"
              prefix={<Icon source="envelope" componentColor="inkLighter" />}
              disabled={!!email}
            />
          </div>
          {email && (
            <>
              <div className={theme.inputTypeRow}>
                <TextField
                  type="text"
                  name="code"
                  onChange={(_v, e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.code}
                  errors={touched.code && errors.code && [errors.code]}
                  placeholder="Verification code"
                  placeholderAlign="left"
                  prefix={<Icon source="chat" componentColor="inkLighter" />}
                />
              </div>
              <div className={theme.inputTypeRow}>
                <TextField
                  type="password"
                  name="password"
                  onChange={(_v, e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.password}
                  errors={
                    touched.password && errors.password && [errors.password]
                  }
                  placeholder="Password"
                  placeholderAlign="left"
                  prefix={<Icon source="lock" componentColor="inkLighter" />}
                  readOnly={readOnly}
                />
              </div>
            </>
          )}
          {email ? (
            <Button
              componentClass={theme.signInButton}
              primary
              disabled={isSubmitting}
              onClick={handleSubmit}
              title="Confirm Reset Password"
              componentSize="large"
            >
              Confirm Reset Password
            </Button>
          ) : (
            <Button
              componentClass={theme.signInButton}
              primary
              disabled={isSubmitting}
              onClick={handleSubmit}
              title="Send Reset Code"
              componentSize="large"
            >
              Send Reset Code
            </Button>
          )}
          <Button
            componentClass={theme.confirmInButton}
            disabled={isSubmitting}
            onClick={onClose}
            title="Cancel"
            componentSize="large"
          >
            Cancel
          </Button>
        </FlexBox>
      )}
    </Formik>
  );
};

export default ForgotPassword;
