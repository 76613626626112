exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BodyText__bodyText__1EBE_{margin:0}.BodyText__sizeSmall__4hq-0{font-size:1.3rem;font-weight:400;line-height:1.6rem;text-transform:none;letter-spacing:normal}@media (min-width:40em){.BodyText__sizeSmall__4hq-0{font-size:1.3rem}}.BodyText__sizeDefault__7mbOT{font-size:1.4rem;font-weight:400;line-height:2rem;text-transform:none;letter-spacing:normal}@media (min-width:40em){.BodyText__sizeDefault__7mbOT{font-size:1.4rem}}.BodyText__sizeLarge__EmJ6-{font-size:1.6rem;font-weight:400;line-height:2.4rem;text-transform:none;letter-spacing:normal}@media (min-width:40em){.BodyText__sizeLarge__EmJ6-{font-size:1.6rem}}.BodyText__colorText__2HA2h{color:#212121}.BodyText__colorDarkest__2yxih{color:#424242}.BodyText__colorDarker__3LrVm{color:#616161}.BodyText__colorMid__cEFBR{color:#757575}.BodyText__colorReverse__14kdG{color:#fff}.BodyText__colorDanger__2Oqpd{color:#d50000}.BodyText__colorLink__uor8F{color:#1976d2}", ""]);

// exports
exports.locals = {
	"bodyText": "BodyText__bodyText__1EBE_",
	"sizeSmall": "BodyText__sizeSmall__4hq-0",
	"sizeDefault": "BodyText__sizeDefault__7mbOT",
	"sizeLarge": "BodyText__sizeLarge__EmJ6-",
	"colorText": "BodyText__colorText__2HA2h",
	"colorDarkest": "BodyText__colorDarkest__2yxih",
	"colorDarker": "BodyText__colorDarker__3LrVm",
	"colorMid": "BodyText__colorMid__cEFBR",
	"colorReverse": "BodyText__colorReverse__14kdG",
	"colorDanger": "BodyText__colorDanger__2Oqpd",
	"colorLink": "BodyText__colorLink__uor8F"
};