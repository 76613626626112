exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Tooltip__aboveTooltip__3cajw{margin:.5rem 1.6rem 1.6rem}.Tooltip__aboveTooltip__3cajw,.Tooltip__rightTooltip__2Wyl5{opacity:1;box-shadow:0 0 0 1px rgba(6,44,82,.1),0 2px 16px rgba(33,43,54,.08);border-radius:3px;pointer-events:none;will-change:opacity,left,top;transition:opacity .2s cubic-bezier(.36,0,1,1)}.Tooltip__rightTooltip__2Wyl5{margin:.5rem 1.6rem}.Tooltip__belowTooltip__2-R1L{margin:.5rem 1.6rem 1.6rem}.Tooltip__belowTooltip__2-R1L,.Tooltip__leftTooltip__2yZVy{opacity:1;box-shadow:0 0 0 1px rgba(6,44,82,.1),0 2px 16px rgba(33,43,54,.08);border-radius:3px;pointer-events:none;will-change:opacity,left,top;transition:opacity .2s cubic-bezier(.36,0,1,1)}.Tooltip__leftTooltip__2yZVy{margin:1.6rem 1.6rem 1.6rem .5rem}.Tooltip__measuring__wNtTK{opacity:0}.Tooltip__positionedAbove__3HQy1{margin:1.6rem 1.6rem .5rem}.Tooltip__positionedAbove__3HQy1 .Tooltip__tip__X4VBu{top:inherit;bottom:0}.Tooltip__light__2sX0L .Tooltip__wrapper__cNP6o{background:#fff;color:#212b36}.Tooltip__light__2sX0L .Tooltip__tip__X4VBu{background:#fff}.Tooltip__wrapper__cNP6o{display:-ms-flexbox;display:flex;background-color:#212b36;color:#fff}.Tooltip__content__3137f,.Tooltip__wrapper__cNP6o{position:relative;border-radius:3px}.Tooltip__content__3137f{margin-top:.4rem;max-width:60rem}.Tooltip__content__3137f ul{margin:0}.Tooltip__content__3137f ul li{padding:0;margin-bottom:.8rem}.Tooltip__content__3137f ul:last-child{margin-bottom:0}.Tooltip__tip__X4VBu{position:absolute;transform:translateX(-50%) rotate(45deg);height:1.4rem;width:1.4rem;background:#212b36;box-shadow:0 0 0 1px rgba(6,44,82,.1),0 2px 16px rgba(33,43,54,.08)}.Tooltip__label__3OevT{padding:0 .8rem 1.2rem}", ""]);

// exports
exports.locals = {
	"aboveTooltip": "Tooltip__aboveTooltip__3cajw",
	"rightTooltip": "Tooltip__rightTooltip__2Wyl5",
	"belowTooltip": "Tooltip__belowTooltip__2-R1L",
	"leftTooltip": "Tooltip__leftTooltip__2yZVy",
	"measuring": "Tooltip__measuring__wNtTK",
	"positionedAbove": "Tooltip__positionedAbove__3HQy1",
	"tip": "Tooltip__tip__X4VBu",
	"light": "Tooltip__light__2sX0L",
	"wrapper": "Tooltip__wrapper__cNP6o",
	"content": "Tooltip__content__3137f",
	"label": "Tooltip__label__3OevT"
};