exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DescriptionList__descriptionList__1nafI{margin:0;padding:0}@media (min-width:36.875em){.DescriptionList__descriptionList__1nafI{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-align:start;align-items:flex-start}}.DescriptionList__term__2oDBE{font-weight:700;padding:1.6rem 0 .8rem}@media (min-width:36.875em){.DescriptionList__term__2oDBE{-ms-flex:0 1 25%;flex:0 1 25%;padding:1.6rem 1.6rem 1.6rem 0}.DescriptionList__description__oDv0s+.DescriptionList__term__2oDBE+.DescriptionList__description__oDv0s{border-top:1px solid #f5f5f5}}.DescriptionList__description__oDv0s{margin-left:0;padding:0 0 1.6rem}.DescriptionList__description__oDv0s+.DescriptionList__term__2oDBE{border-top:1px solid #f5f5f5}@media (min-width:36.875em){.DescriptionList__description__oDv0s{-ms-flex:1 1 51%;flex:1 1 51%;padding:1.6rem 0}.DescriptionList__description__oDv0s+.DescriptionList__term__2oDBE+.DescriptionList__description__oDv0s{border-top:1px solid #f5f5f5}}.DescriptionList__naked__3NUtM{list-style-type:none}.DescriptionList__description-list-divider__2Xd1P>dt:nth-child(n+2){margin-top:2rem;padding-top:2rem;border-top:1px solid #e5e5e5}", ""]);

// exports
exports.locals = {
	"descriptionList": "DescriptionList__descriptionList__1nafI",
	"term": "DescriptionList__term__2oDBE",
	"description": "DescriptionList__description__oDv0s",
	"naked": "DescriptionList__naked__3NUtM",
	"description-list-divider": "DescriptionList__description-list-divider__2Xd1P"
};